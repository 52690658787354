import React, { useState, useEffect, useMemo } from "react";
import { autoEffect, view } from "@risingstack/react-easy-state";
import { useLocation } from "react-router-dom";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableContainer,
} from "@mui/material";
import Loader from "components/backoffice/utils/loader";
import BillsToolbar from "./billsToolbar";
import { BoxSC } from "../../styled/styled";
import TableSortLabel from "../../utils/tableSortLabel";
import BillDetailModal from "components/backoffice/utils/billDetailModal";
import { sortArray } from "./sort";
import { format } from "date-fns";

const BillsResult = view(({ bills }) => {
  const location = useLocation();

  const [allBills, setAllBills] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);

  const [input, setInput] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const [openDetail, setOpenDetail] = useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);
  const [id, setId] = useState("");
  const [success, setSuccess] = useState([]);

  const headers = [
    { name: "ID računa", field: "id", sortable: true },
    { name: "Vlasnik", field: "name", sortable: true },
    { name: "Datum kreiranja", field: "bill_date", sortable: true },
    { name: "Ulica", field: "street_name", sortable: true },
    { name: "Kućni broj", field: "street_number", sortable: true },
    { name: "Potrošnja", field: "bill_consumption", sortable: false },
    { name: "Iznos u eurima", field: "bill_amount_euro", sortable: false },
  ];

  const handleID = (id) => {
    setId(id);
  };

  const billsData = useMemo(() => {
    let computedBills = allBills;
    if (!computedBills) computedBills = [];

    if (startDate || endDate) {
      computedBills = computedBills.filter((bill) => {
        if (startDate) {
          var from = new Date(startDate);
          from.setDate(from.getDate() - 1);
        }
        if (endDate) {
          var to = new Date(endDate);
          to.setDate(to.getDate() - 1);
        }
        const today = new Date(bill.bill_date);
        if (today >= from && today <= to) {
          return true;
        } else if (!to && today >= from) {
          return true;
        } else if (!from && today <= to) {
          return true;
        } else {
          return null;
        }
      });
    }

    if (input) {
      computedBills = computedBills.filter(
        (bill) =>
          bill.id == input.toLowerCase() ||
          bill.Device?.id == input.toLowerCase() ||
          bill.Device?.Owner?.name.toLowerCase().includes(input.toLowerCase()) ||
          bill.Device?.Street.name
            .toLowerCase()
            .includes(input.toLowerCase()) ||
          bill.Device?.street_number
            .toLowerCase()
            .includes(input.toLowerCase()) ||
          bill.reading_date.toLowerCase().includes(input.toLowerCase())
      );
    }
    if (computedBills) setTotalItems(computedBills.length);
    //Sorting comments
    if (sortingField) {
      sortArray(computedBills, sortingField, sortingOrder);
    } else {
      sortArray(computedBills, "bill_date", "desc");
    }
    //Current Page slice
    return computedBills.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [
    allBills,
    page,
    rowsPerPage,
    input,
    sortingField,
    sortingOrder,
    startDate,
    endDate,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const changeFormat = (updated_at) => {
  //   const mys = updated_at.slice(0, 10);
  //   const parts = mys.split("-");

  //   if (parts[2][0].toString() === "0") {
  //     parts[2] = parts[2][1];
  //   }
  //   if (parts[1][0].toString() === "0") {
  //     parts[1] = parts[1][1];
  //   }
  //   return parts[2] + "." + parts[1] + "." + parts[0] + ".";
  // };

  const onSortingChange = (field) => {
    const order = sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
  };

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && startDate > endDate) {
      alert("Završni datum ne smije biti manji od početnog datuma.");
      setEndDate("");
    }
  }, [startDate, endDate]);

  autoEffect(async () => {
    if (!bills.bills) {
      await bills.getBills();
      setAllBills(bills.bills);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      bills.bills = null;
    };
    // eslint-disable-next-line
  }, []);

  if (billsData.length === 0 && !input && !startDate && !endDate) {
    return (
      <Box>
        <BillsToolbar
          input={input}
          setInput={setInput}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          disabled={true}
        />
        {bills.isLoading ? (
          <Loader />
        ) : (
          <Typography
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            Nema niti jednog računa
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <BillsToolbar
        input={input}
        setInput={setInput}
        setPage={setPage}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        disabled={false}
      />

      {bills.bills && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "100%",
            overflow: "auto",
            mb: 2,
            mx: 2,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map(({ name, field, sortable }) => (
                    <TableCell
                      key={field}
                      onClick={() => (sortable ? onSortingChange(field) : null)}
                    >
                      <BoxSC>
                        {name}
                        <TableSortLabel
                          sortingColumn={field}
                          sortingField={sortingField}
                          orderDirection={sortingOrder}
                        />
                      </BoxSC>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(input || startDate || endDate) && billsData.length === 0 && (
                  <Box sx={{ m: 2 }}>
                    <Typography color="textPrimary" variant="body1">
                      Nisu pronađeni rezultati.
                    </Typography>
                  </Box>
                )}
                {billsData.map((bill) => (
                  <TableRow
                    hover
                    key={bill.id}
                    onClick={() => {
                      handleOpenDetail();
                      handleID(bill.id);
                    }}
                  >
                    <TableCell
                      padding="normal"
                      size="medium"
                      style={{ width: "10%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {bill.id}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {bill.Device?.Owner?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {bill.reading_date &&
                            format(new Date(bill.reading_date), "d.M.yyyy.")}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "20%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {bill.Device?.Street.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "10%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {bill.Device?.street_number}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "10%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {bill.bill_consumption ? (
                          <Typography color="textPrimary" variant="body1">
                            {bill.bill_consumption}
                          </Typography>
                        ) : (
                          <Typography color="textPrimary" variant="body1">
                            -
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      padding="normal"
                      size="small"
                      style={{ width: "10%" }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {bill.bill_amount_euro ? (
                          <Typography color="textPrimary" variant="body1">
                            {bill.bill_amount_euro}
                          </Typography>
                        ) : (
                          <Typography color="textPrimary" variant="body1">
                            -
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[
                20,
                50,
                100,
                { label: "Prikaži sve", value: totalItems },
              ]}
              labelRowsPerPage={<span>Redovi:</span>}
              labelDisplayedRows={({ page }) => {
                return `Stranica: ${page + 1}`;
              }}
              backIconButtonProps={{
                color: "primary",
              }}
              nextIconButtonProps={{ color: "primary" }}
              SelectProps={{
                inputProps: {
                  "aria-label": "Broj stranica",
                },
              }}
              showFirstButton={true}
              showLastButton={true}
            />
          </TableContainer>
          {openDetail ? (
            <BillDetailModal
              open={openDetail}
              id={id}
              handleClose={handleCloseDetail}
              setSuccess={setSuccess}
            />
          ) : null}
        </Card>
      )}
    </>
  );
});

export default BillsResult;
