import React, { useEffect, useState } from "react";

import { bills } from "../../../stores/BaseStore";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { Button, Box, styled, Typography, Card } from "@mui/material";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { useLocation } from "react-router-dom";
import Loader from "components/backoffice/utils/loader";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 600,
  bgcolor: "#f3f5f9",
  border: "1px solid #d3d3d3",
  borderRadius: 2,
};

const BillDetailModal = view(({ id, open, handleClose, setSuccess }) => {
  const location = useLocation();

  const [counter, setCounter] = useState(0);

  autoEffect(async () => {
    if (!bills.bill) {
      await bills.getBill(id);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      bills.bill = null;
      bills.success = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleDownloadPdf = () => {
    if (bills?.bill?.erp_bill_id) {
      bills.downloadPDF(bills.bill.erp_bill_id);
    }
  };

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      disableScrollLock
    >
      <Box sx={style}>
        {bills.bill ? (
          <>
            <Box
              sx={{
                paddingTop: "12px",
                mx: 2,
                mb: 2,
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "1.25rem",
                  color: "#464C4C",
                  marginBottom: "10px",
                }}
              >
                Detalji računa
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  color: "rgba(0, 0, 0, 0.54)",
                  marginBottom: "10px",
                }}
              >
                {bills.bill.name}
              </Typography>
            </Box>

            <Box
              sx={{
                boxShadow: 1,
                mx: 2,
                mb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  mb: 2,
                }}
              >
                <Box sx={{ maxWidth: 600, flexGrow: 1, mx: 2 }}>
                  <Typography>ID: {bills.bill.id}</Typography>
                  <Typography>Erp ID: {bills.bill.erp_bill_id}</Typography>
                  <Typography>
                    Potrošnja: {bills.bill.bill_consumption}
                  </Typography>
                  <Typography>
                    Iznos u eurima: {bills.bill.bill_amount_euro}
                  </Typography>
                </Box>
              </Card>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                justifyContent: "flex-end",
                marginRight: "12px",
                marginBottom: "12px",
              }}
            >
              <Button
                onClick={handleDownloadPdf}
                disabled={bills.isLoadingPDF}
                style={{ backgroundColor: "#00BAFF" }}
                variant="contained"
              >
                Skini u PDF
              </Button>
              <Button
                onClick={() => {}}
                style={{ backgroundColor: "#00BAFF" }}
                variant="contained"
              >
                {counter === 0 ? "Uredi" : "Spremi"}
              </Button>
              <Button
                onClick={handleClose}
                style={{ backgroundColor: "#f50057" }}
                variant="contained"
              >
                Odustani
              </Button>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </StyledModal>
  );
});

export default BillDetailModal;
