export const sortArray = (arr, col, orderBy) => {
  switch (col) {
    case "id":
      switch (orderBy) {
        case "asc":
        default:
          return arr.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
        case "desc":
          return arr.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
      }
    case "bill_date":
      switch (orderBy) {
        case "asc":
        default:
          return arr.sort((a, b) => {
            return a.bill_date?.localeCompare(b.bill_date, undefined, {
              numeric: false,

              sensitivity: "base",
            });
          });
        case "desc":
          return arr.sort((a, b) => {
            return b.bill_date?.localeCompare(a.bill_date, undefined, {
              numeric: false,

              sensitivity: "base",
            });
          });
      }
    case "name":
      switch (orderBy) {
        case "desc":
        default:
          return arr.sort((a, b) => {
            return a.Device?.Owner?.name.localeCompare(
              b.Device?.Owner?.name,
              undefined,
              {
                numeric: false,
                sensitivity: "base",
              }
            );
          });
        case "asc":
          return arr.sort((a, b) => {
            return b.Device?.Owner?.name.localeCompare(
              a.Device?.Owner?.name,
              undefined,
              {
                numeric: false,
                sensitivity: "base",
              }
            );
          });
      }

    case "street_name":
      switch (orderBy) {
        case "desc":
        default:
          return arr.sort((a, b) => {
            return a.Device?.Street.name.localeCompare(
              b.Device?.Street.name,
              undefined,
              {
                numeric: false,
                sensitivity: "base",
              }
            );
          });
        case "asc":
          return arr.sort((a, b) => {
            return b.Device?.Street.name.localeCompare(
              a.Device?.Street.name,
              undefined,
              {
                numeric: false,
                sensitivity: "base",
              }
            );
          });
      }
    case "street_number":
      switch (orderBy) {
        case "asc":
        default:
          return arr.sort((a, b) =>
            a.Device?.street_number > b.Device?.street_number
              ? 1
              : b.Device?.street_number > a.Device?.street_number
              ? -1
              : 0
          );
        case "desc":
          return arr.sort((a, b) =>
            a.Device?.street_number < b.Device?.street_number
              ? 1
              : b.Device?.street_number < a.Device?.street_number
              ? -1
              : 0
          );
      }
    default:
      return arr;
  }
};
